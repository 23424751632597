export const EVENTS_MAPPING = {
  AddSubscriber: {
    meta:   "CompleteRegistration",
    google: "AW-16589788467/iGZ9CLTj5OMZELOq0OY9",
  },
  ViewAR: {
    meta:   "ViewContent",
    google: "AW-16589788467/bfFICJPp5OMZELOq0OY9",
  },
  ViewBuy: {
    meta:   "FindLocation",
    google: "AW-16589788467/eHnTCJbp5OMZELOq0OY9",
  },
  AddToCart: {
    meta:   "AddToCart",
    google: "AW-16589788467/7zUmCOyp8t4ZELOq0OY9",
  },
  CustomizeProduct: {
    meta:   "CustomizeProduct",
    google: "AW-16589788467/8mHiCOmp8t4ZELOq0OY9",
  },
  InitiateCheckout: {
    meta:   "InitiateCheckout",
    google: "AW-16589788467/rwADCI742eMZELOq0OY9",
  }
}